import { inject }       from 'aurelia-framework';
import { RelativeTime } from 'aurelia-i18n';

@inject(RelativeTime)
export class RelativeTimeValueConverter {

    /**
     * Constructor
     *
     * @param relativeTime
     */
    constructor(relativeTime) {
        this.relativeTime = relativeTime;
    }

    /**
     *
     * @param value
     *
     * @returns {*}
     */
    toView(value) {
        let time = new Date(value);

        return this.relativeTime.getRelativeTime(time);
    }
}
