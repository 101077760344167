export class ToFixedValueConverter {

    /**
     *
     * @param value
     * @param decimalPlaces
     *
     * @returns {*}
     */
    toView(value, decimalPlaces = 2) {
        return value.toFixed(decimalPlaces);
    }

}

export class RemoveTrailingZerosValueConverter {

    /**
     *
     * @param value
     *
     * @returns {*}
     */
    toView(value) {
        return Number(value);
    }

}

export class CurrencyValueConverter {

    /**
     *
     * @param value
     *
     * @returns {*}
     */
    toView(value) {
        return `${value} €`;
    }

}
