import { PLATFORM } from 'aurelia-pal';

export function configure(config) {
    config.globalResources(
        [
            PLATFORM.moduleName('./date-converters'),
            PLATFORM.moduleName('./meta-converter'),
            PLATFORM.moduleName('./number-converters'),
            PLATFORM.moduleName('./relative-time-converter'),
            PLATFORM.moduleName('./text-converters'),
        ],
    );
}
