import moment from 'moment';

export class DateFormatValueConverter {

    /**
     *
     * @param value
     * @param format
     *
     * @returns {*}
     */
    toView(value, format = 'YYYY-MM-DD') {
        if (!isEmpty(value)) {
            return moment(value).format(format);
        }

        return value;
    }

}

export class DateTimeFormatValueConverter {

    /**
     *
     * @param value
     * @param format
     *
     * @returns {*}
     */
    toView(value, format = 'YYYY-MM-DD HH:mm') {
        if (!isEmpty(value)) {
            return moment(value).format(format);
        }

        return value;
    }

}

export class TimeFormatValueConverter {

    /**
     *
     * @param value
     * @param format
     *
     * @returns {*}
     */
    toView(value, format = 'HH:mm') {
        if (!isEmpty(value)) {
            return moment(value, 'HH:mm:ss').format(format);
        }

        return value;
    }

}
