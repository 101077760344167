import { inject }                                                                           from 'aurelia-framework';
import { CurrencyValueConverter }                                                           from 'resources/value-converters/number-converters';
import { DateFormatValueConverter, DateTimeFormatValueConverter, TimeFormatValueConverter } from 'resources/value-converters/date-converters';
import { LowerCaseValueConverter, UpperCaseValueConverter }                                 from 'resources/value-converters/text-converters';

@inject(
    CurrencyValueConverter,
    DateFormatValueConverter,
    DateTimeFormatValueConverter,
    TimeFormatValueConverter,
    UpperCaseValueConverter,
    LowerCaseValueConverter,
)
export class MetaValueConverter {

    constructor(currencyValueConverter, dateFormatValueConverter, dateTimeFormatValueConverter, timeFormatValueConverter, upperCaseValueConverter, lowerCaseValueConverter) {
        this.currency       = currencyValueConverter;
        this.dateFormat     = dateFormatValueConverter;
        this.dateTimeFormat = dateTimeFormatValueConverter;
        this.timeFormat     = timeFormatValueConverter;
        this.upperCase      = upperCaseValueConverter;
        this.lowerCase      = lowerCaseValueConverter;
    }

    /**
     * Converts value to view
     *
     * @param value
     * @param valueConverters
     *
     * @returns {*}
     */
    toView(value, valueConverters) {
        if (valueConverters instanceof Array) {
            for (let i = 0; i < valueConverters.length; i++) {
                let valueConverter = this[valueConverters[i].name];
                let format         = valueConverters[i].format;

                if (valueConverter && valueConverter.toView) {
                    value = valueConverter.toView(value, format);
                }
            }
        }

        return value;
    }

}
